import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Constants from "../config";
import useWindowDimensions from "../tools/window_resolution";
import CommentCard from "../main components/commentcard";
import QuestionAcc from "../main components/questionacc";
import Uploading from "../admin components/uploading";
import { useLocation } from "react-router-dom";

export default function MainPage(){
    const { hash } = useLocation();
    const screenheight = useWindowDimensions().height;
    const screenwidth = useWindowDimensions().width;
    const contentWidth = screenwidth > 700 ? 700 : screenwidth;

    const innerContentWidth = contentWidth -50;
    const defaultSpace = contentWidth/12;
    const [generalData,setGeneralData] = useState({});
    const [comments,setComments] = useState([{}]);
    const [examples,setExamples] = useState([{}]);
    const [questions,setQuestions] = useState([{}]);
    const [prices,setPrices] = useState([{}]);
    const [promoClosed,setPromoClosed] = useState(false);
    const [promoActive,setPromoActive] = useState(false);


    const [imgLoaded1,setImgLoaded1] = useState(false);
    const [imgLoaded2,setImgLoaded2] = useState(false);
    const isLoaded = useMemo(
        () => (imgLoaded1 && imgLoaded2)
        , [imgLoaded1,imgLoaded2] 
    )
    const dataLoaded = 5;

    const serviceRef = useRef(null);
    const priceRef = useRef(null);
    const companyRef = useRef(null);
    const topRef = useRef(null);
    const promoRef = useRef(null);

    const blockColor = "#bed9e4";

    const [scrollPosition, setScrollPosition] = useState(0);

    const elemPositions = ["menu","comments","questions","services"];

    const [elemPos, setElemPos] = useState(elemPositions);

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
        if(!promoClosed && position > 600)
            setPromoActive(true);
    };

    const loaded1 = (i) =>{  
        setImgLoaded1(true);
    }
    const loaded2 = (i) =>{  
        setImgLoaded2(true);
    }

    // const loaded = useCallback((index) => {
    //     var newObj = {};
    //     Object.assign(newObj,imgLoaded);
    //     newObj[index] = true;
    //     setImgLoaded(newObj);
    // }, [])
    
    
    useEffect(() => {   
        //preload();     
        if(hash=="#price")
            setTimeout(() => {                
                priceRef.current.scrollIntoView();
            }, 1500);
        window.addEventListener('scroll', handleScroll, { passive: true });
        console.log(hash);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const getGeneralData = () => {
        fetch(Constants.apiurl + Constants.generalPath)
        .then((response) => response.json())
        .then((data) => {
            setGeneralData(data);
            setElemPos(data.contentOrder);
        })
        .catch((err) => {            
           console.log(err.message);
        });
    }

    const getComments = () => {
        fetch(Constants.apiurl + Constants.commentsPath)
        .then((response) => response.json())
        .then((data) => {
            setComments(data);            
        })
        .catch((err) => {            
           console.log(err.message);
        });
    }

    const getQuestions = () => {
        fetch(Constants.apiurl + Constants.questionsPath)
        .then((response) => response.json())
        .then((data) => {            
            setQuestions(data);            
        })
        .catch((err) => {            
           console.log(err.message);
        });
    }

    const getExamples = () => {
        fetch(Constants.apiurl + Constants.examplesPath)
        .then((response) => response.json())
        .then((data) => {
            setExamples(data);            
        })
        .catch((err) => {
            console.log(err.message);
        });
    }
    const getPrices = () => {
        fetch(Constants.apiurl + Constants.pricesPath)
        .then((response) => response.json())
        .then((data) => {
            setPrices(data);
        })
        .catch((err) => {            
            console.log(err.message);
        });
    }

    useEffect(() => {
        if(generalData.imageHead == undefined){
            getGeneralData();
            getComments();
            getExamples();
            getQuestions();
            getPrices();
        }
     }, []);

     const pageStyle = {
        backgroundColor:"rgb(200 211 215)",        
        width: screenwidth,
        height: screenheight,
        position:"fixed",
        zIndex: "-2",
    };

    const layoutStyle = {
        display:"flex",
        justifyContent:"center",        
    };

    const contentStyle = {
        width: contentWidth,
        paddingTop:"70px",
        paddingBottom:"250px",
        backgroundColor:"#e0edf3",
        display:"flex",
        justifyContent:"center",   
        flexWrap: "wrap"
    };

    const header = {
        width: innerContentWidth,
        display:"block",
        marginBottom:"20px"
    };

    const header2 = {
        width: innerContentWidth,
        display:"block",
        paddingTop:"10px"
    };

    const exampleStyle = {
        width: innerContentWidth,
        display:"block",
        paddingTop: defaultSpace
    };

    const areaStyle = {
        width: innerContentWidth,
        display:"block",
        paddingTop: defaultSpace
    };

    const fontSize = {
        fontSize: contentWidth/18
    }

    const commentsBlock = {
        backgroundColor:blockColor,
        width: contentWidth - 30,
        marginTop: defaultSpace,
    };

    const questionsBlock = {
        backgroundColor:blockColor,
        width: innerContentWidth,
        marginTop: defaultSpace,
        display:"flex",
        justifyContent:"center",
        flexWrap:"wrap"
    };

    const commentPad = {
        width: contentWidth - 30,
        backgroundColor:blockColor,
        height: "250px",
        display:"flex",
        overflow:"auto",
        alignItems:"center"
    };

    const promoBlock = {
        width: innerContentWidth,
        backgroundColor: blockColor,
        marginTop: defaultSpace,
        paddingBottom:"15px"
    }
    const promoHeader = {
        paddingTop:"15px",
        paddingBottom:"10px",
        color:"#cb1517",
        fontSize:contentWidth/20,
        fontWeight:"900"
    }
    
    const promoBody = {
        paddingLeft:"10px",
        paddingRight:"10px",
        backgroundColor: blockColor,
        fontSize: contentWidth/23
    }
    
    const companyTitle = {
        paddingTop:"15px",
        paddingBottom:"10px",
        backgroundColor: blockColor,
        fontSize:contentWidth/20,
        fontWeight:"900"
    }
    
    const areaTitle = {
        paddingTop:"15px",
        paddingBottom:"10px",
        backgroundColor: blockColor,
        fontSize:contentWidth/15,
        fontWeight:"900"
    }
    
    const areaDescr = {
        paddingTop:"15px",
        paddingBottom:"20px",
        backgroundColor: blockColor,
        fontSize:contentWidth/20,
        fontWeight:"900"
    }
    
    const priceBlock = {
        width: innerContentWidth,
        backgroundColor: blockColor,
        marginTop: defaultSpace,
        display:"flex",
        flexWrap:"wrap"
    }

    const tableLayout ={
        border:"2px solid #1b294c",
        width:"49%",
        height:"30px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    }

    const tableStyle = {
        width:"100%",
        fontSize:contentWidth/24,
        borderCollapse:"collapse"
    };

    const menuStyle = {
        display:"flex",
        paddingTop: defaultSpace
    }

    const menuItem = {
        width:innerContentWidth/3.4,
        padding:"10px",
        paddingTop:"0px",
    }

    const socialStyle = {
        display:"flex",
        width:contentWidth,
        backgroundColor:"rgba(255,255,255,.5)",
        justifyContent:"center",
        position:"fixed",
        bottom:"0px",
        zIndex:"4"
    }

    const socialItem = {
        width:innerContentWidth/5,
        padding:"10px",
        paddingBottom:"0px"
    }

    const popupStyle = {
        display: !promoClosed && promoActive ? "flex" : "none",
        width:contentWidth/1.5,
        justifyContent:"center",
        alignContent:"center",
        position:"fixed",
        border:"solid 3px red",
        zIndex:"4",
        top:"50%",
        transform: "translate(0, -50%)"
    }

    const popupImg = {
        width:contentWidth/1.5,
    }

    const closePopup = {
        position:"fixed",
        width:"20px",
        right:"-20px",
        top:"-20px",
        height:"20px",
        padding:"8px",
        borderRadius:"50%",
        backgroundColor:"rgba(0,0,0,.3)",
    }

    const goTopStyle = {
        display: scrollPosition > 400 ? "flex" : "none",
        position:"fixed",
        right: (screenwidth - contentWidth)/2 + 20,
        bottom:innerContentWidth/5 + 40,
        zIndex:"4"
    }

    const goTopIcon = {
        width:innerContentWidth/6,
    }

    const socialitembox ={
        width:"50%",
        display:"flex",
        justifyContent:"center",
        flexWrap:"wrap"
    }

    const questionsHtml = questions.map(item=>
            <QuestionAcc 
                key={item.question+item.answer}
                q={item.question}
                a={item.answer}
                innerContentWidth = {innerContentWidth}
                contentWidth ={contentWidth}
            />
    );

    const commentsHtml = comments.map(item => 
        <CommentCard 
            key={item.id+item.rating}
            rating={item.rating} 
            age={item.age} 
            sex={item.sex} 
            type={item.type} 
            date={item.date} 
            comment={item.comment} 
        />
    );

    const pricesHtml = prices.map(item=>
      <tr key={item.service+item.price}>                        
        <td >{item.service}</td>
        <td >{item.price}</td>
      </tr>  
    );

    const description = () => {
        return(
            <>
            <h1 class="description">
            私たち【スキマ】ハウスクリーニングサービスは、東京都、神奈川県一部、埼玉県一部を対象に提供しています。大手ハウスメーカーの美装・清掃を請け負っている経験と知識が豊富なスタッフがプロの技術で対応いたします。エアコンクリーニングやキッチン、お風呂、洗面所、換気扇、トイレなどの水回りクリーニングを中心に、お部屋を隅々まで清潔に保つための高品質な清掃業務を行っています。高品質な安心と信頼のハウスクリーニングをお求めの方は、ぜひ私たち【スキマ】のハウスクリーニングサービスをご利用ください。
            </h1>            
            <h1 class="description">
            【スキマ】ハウスクリーニングサービスは、東京都、神奈川県一部、埼玉県一部を対象に、エアコンクリーニングやキッチン、お風呂、洗面所、換気扇、トイレなど水回りクリーニングの清掃業務を行っています。大手ハウスメーカーの美装・清掃を請け負っている経験と知識が豊富なスタッフがプロの技術でご対応し高品質な安心と信頼のハウスクリーニングをぜひご体験ください。
             </h1>            
             <h1 class="description">
             【スキマ】ハウスクリーニングは、東京都、神奈川県一部、埼玉県一部を対象に、エアコンクリーニングやキッチン、お風呂、洗面所、換気扇、トイレなど水回りクリーニングの清掃業務を行っています。高品質な安心と信頼のハウスクリーニングをぜひご体験ください。
              </h1>
            </>
        )
    }

    const exampleText = (text) => {
        return (
            <div style={{paddingTop:"15px",paddingBottom:"25px"}}>
                <div style={fontSize} className="defaultFont text-center">{text}</div>
            </div>
        )
    };

    const examplesHtml = examples.map(item => 
        <div key={item.id+item.image}>
            <img alt="example" style={exampleStyle} src={Constants.imagesUrl + item.image} />
            {item.text !=="" && item.text !== undefined ? exampleText(item.text) : ""}
        </div>
    );

    const menuVar = <div style={menuStyle}>
    <img alt="service" onClick={()=>serviceRef.current.scrollIntoView()} style={menuItem} src="bubble_icon.png"/>
    <img alt="price" onClick={()=>priceRef.current.scrollIntoView()} style={menuItem} src="bubble_icon2.png"/>
    <img alt="info" onClick={()=>companyRef.current.scrollIntoView()} style={menuItem} src="bubble_icon3.png"/>
</div>  ;

    const commentsVar =                     <div style={commentsBlock}>
    <div className="defaultFont text-center padding-top-10" style={fontSize}>ロコミ</div>
    <div style={commentPad}>
        {commentsHtml}
    </div>
</div>;

    const servicesVar = <>

        <div ref={serviceRef} style={{width:contentWidth,height:"1px"}}></div>
        {examplesHtml}
        </>;

    const questionsVar = <div style={questionsBlock}>
    <div style={{display:"flex",
                alignContent:"center",
                padding:"10px",}}>
        <div style={fontSize} className="defaultFont text-center">よくある質問</div>
    </div>
    {questionsHtml}
</div>;

const elemWithNames = [
    {name:"menu", value:menuVar},
    {name:"comments", value:commentsVar},
    {name:"services", value:servicesVar},
    {name:"questions", value:questionsVar},
];
    return(
        <>  
        {/* <Uploading inprogress={dataInProgressState !== dataLoaded}/> */}

        <div ref={topRef}></div>
            <div  style={pageStyle}>            
            </div>  
            <div style={layoutStyle}>
                <div style={popupStyle}>
                    <img onClick={
                        ()=>{
                            setPromoClosed(true);
                            promoRef.current.scrollIntoView();
                        }
                        } alt="promo" style={popupImg} src={Constants.imagesUrl + generalData.popupimage}/>
                    <img alt="cross" onClick={()=>setPromoClosed(true)} src="cross.svg" style={closePopup}/>
                 </div>

                <div style={contentStyle}>  
                    {!isLoaded ? 
                        <div className="loader-box">
                            <div className="animation-container">
                                <div className="loader">

                                </div>
                            </div>
                        </div>     
                        :
                        ""
                    } 
                    <div style={socialStyle}>
                        <div style={socialitembox}>
                            <div className="center-element">
                                <a href={"tel:"+ generalData.phone}> 
                                    <img alt="phone" style={socialItem} src="phone_icon.png"/>
                                </a>
                            </div>
                            <div style={{fontSize:"20px"}} className="center-element defaultFont"> 9:00-18:00</div>
                        </div>  
                        <div style={socialitembox}> 
                            <div className="center-element">
                                <a href={generalData.line}> 
                                    <img alt="line" style={socialItem} src="line_icon.png"/>
                                </a>
                            </div>
                            <div style={{fontSize:"20px"}} className="center-element defaultFont green-font">24H</div>                    
                        </div>
                    </div>

                    <div style={goTopStyle}>
                        <img alt="top" onClick={()=>topRef.current.scrollIntoView()} style={goTopIcon} src="gototop_button.png"/>
                    </div>
                    <img onLoad={()=>loaded1()} alt="header" style={header} src={Constants.imagesUrl+generalData.imageHead} />                             
                    <div style={fontSize} className="defaultFont">
                        スキマ ハウスクリーニング サービス
                    </div>
                    <img onLoad={()=>loaded2()} alt="header2" style={header2} src={Constants.imagesUrl+generalData.imageBody}/>
                    {elemPos != null ?
                        elemPos.map(x=>
                        <>
                            {elemWithNames.find(y=>y.name == x).value}
                        </>    
                        )
                        :""
                    }

                    <div>
                        <img alt="example" style={exampleStyle} src={Constants.imagesUrl + "payment.jpg"} />
                    </div>                    
                    <div style={promoBlock}>
                        <div className="defaultFont text-center" style={areaTitle}>対応エリア</div>
                        <div className="defaultFont text-center" style={areaDescr}>
                            <div>
                                {generalData.area1}
                            </div> 
                            <div>
                                {generalData.area2}
                            </div>
                        </div>
                    </div>
                    <div ref={promoRef} style={promoBlock}>
                        <div className="text-center" style={promoHeader}>{generalData.promotitle}</div>
                        <div  className="defaultFont" style={promoBody}>{generalData.promotext}</div>
                    </div>
                    <div ref={priceRef} style={priceBlock} className="defaultFont text-center">
                        <table style={tableStyle}>
                            <thead>
                            <tr>
                                <th style={{width:"50%"}}>サービス</th>
                                <th style={{width:"50%"}}>料金</th>
                            </tr>
                            </thead>
                            <tbody>
                            {pricesHtml}
                            </tbody>
                            </table>
                    </div>
                    {generalData.googleform !=null ?
                    <div style={promoBlock} dangerouslySetInnerHTML={{ __html: generalData.googleform }}>
                    </div>
                    :
                    ""
                    }
                    
                    <div ref={companyRef} style={promoBlock}>
                        <div className="defaultFont text-center" style={companyTitle}>{generalData.companytitle}</div>
                        <div  className="defaultFont" style={promoBody}>{generalData.companytext}</div>
                    </div>
                <div style={{"paddingTop":"40px", 
                "fontSize":contentWidth/30
                ,"textAlign":"left",
                "width":innerContentWidth}}>
                    <a className="defaultFont" href="privacy">特定商取引法に基づく表記</a>
                </div>
                </div>
            </div>
            {description()}
        </>
    )
}